import { FormattedMessage, useIntl } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import classes from './FAQ.module.css';

function FAQ() {
    const intl = useIntl();

    return (
        <section id="faq" className={classes['faq']} aria-labelledby="faq_title">
            <CustomContainer>
                <Typography variant="h2" component="h2" color="primary" id="faq_title">
                    <FormattedMessage
                        defaultMessage="Частые вопросы"
                        id="Help_etxeQP"
                        description="блок частые вопросы"
                    />
                </Typography>

                <div className={classes['faq__questions']}>
                    <Accordion disableGutters>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon fontSize="large" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography variant="h3" className={classes['faq__question']}>
                                <FormattedMessage
                                    defaultMessage="Не находится или не подключается мощеметр или пульсометр"
                                    id="Help_2QgAeF"
                                    description="частые вопросы"
                                />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className={classes['faq__answer']}>
                                <FormattedMessage
                                    defaultMessage="Особенно относится к подключению по протоколу BlueTooth."
                                    id="Help_fZnMtI"
                                    description="частые вопросы"
                                />
                            </p>

                            <p className={classes['faq__answer']}>
                                <FormattedMessage
                                    defaultMessage="Самая известная причина почему мощемер или пульсометр не находится заключается в том, что к нему уже присоединена другая программа по протоколу Bluetooth (Zwift, велокомпьютер, другой велосимулятор или даже сам Windows, если изначально вами было установлено сопряжение с мощемером через Windows)."
                                    id="Help_J8yUau"
                                    description="частые вопросы"
                                />
                            </p>

                            <ul className={classes['faq__solution-list']}>
                                <li>
                                    <p className={classes['faq__solution']}>
                                        <FormattedMessage
                                            defaultMessage="Для решения проблемы необходимо отключить программу от устройства, что высвободит его для нового подключения и он будет виден в Wattattack."
                                            id="Help_fLB56y"
                                            description="частые вопросы"
                                        />
                                    </p>
                                </li>
                                <li>
                                    <p className={classes['faq__solution']}>
                                        <FormattedMessage
                                            defaultMessage="Проверьте заряд батарей мощемера/пульсометра."
                                            id="Help_fLB57y"
                                            description="частые вопросы"
                                        />
                                    </p>
                                </li>
                                <li>
                                    <p className={classes['faq__solution']}>
                                        <FormattedMessage
                                            defaultMessage="Подвиньте свой станок/велосипед ближе к компьютеру."
                                            id="Help_fLB58y"
                                            description="частые вопросы"
                                        />
                                    </p>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon fontSize="large" />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography variant="h3" className={classes['faq__question']}>
                                <FormattedMessage
                                    defaultMessage="Есть ли автоматическая интеграция и выгрузка fit файлов в Strava, Garmin, TrainingPeaks?"
                                    id="Help_D1fOI4"
                                    description="частые вопросы"
                                />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className={classes['faq__answer']}>
                                <FormattedMessage
                                    defaultMessage="Автоматической интеграции пока нет. Но можно загрузить fit файл тренировки вручную."
                                    id="Help_+adbAP"
                                    description="частые вопросы"
                                />
                            </p>

                            <p className={classes['faq__answer']}>
                                <FormattedMessage
                                    defaultMessage="После завершения тренировки вы можете найти fit файл в папке C:/Users/ (имя вашего пользователя) /AppData/Local/WattAttack/Saved/WourkoutSaves."
                                    id="Help_zt7Y1k"
                                    description="частые вопросы"
                                />
                            </p>

                            <p className={classes['faq__answer']}>
                                <FormattedMessage
                                    defaultMessage="Папка AppData может быть скрыта, поэтому для ее отображения необходимо включить отображение скрытых папок и файлов."
                                    id="Help_WHl/cU"
                                    description="частые вопросы"
                                />
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon fontSize="large" />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Typography variant="h3" className={classes['faq__question']}>
                                <FormattedMessage
                                    defaultMessage="Что делать, если при запуске установщика появляется предупреждение «Система Windows защитила ваш компьютер»?"
                                    id="Help_UvlHAL"
                                    description="частые вопросы"
                                />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className={classes['faq__answer']}>
                                <FormattedMessage
                                    defaultMessage="При запуске скачанного установочного файла система может вывести сообщение о подозрении в отношении неопознанного приложения с надписью «Система Windows защитила ваш компьютер». Подобное может произойти и при использовании какого-то другого антивирусного ПО. В этом случае надо нажать «Подробнее» и далее нажать на появившуюся кнопку «Выполнить». Удачных тренировок!"
                                    id="Help_qFu0K4"
                                    description="частые вопросы"
                                />
                            </p>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <Typography variant="subtitle1" component="p" className={classes['faq__feedback']}>
                    <FormattedMessage
                        defaultMessage="По всем остальным вопросам, относящимся к проблемам в работе приложения, а также предложениям по улучшению работы симулятора, пожалуйста, пишите на адрес"
                        id="Help_6sjVTD"
                        description="блок частые вопросы"
                    />
                </Typography>
                <a
                    className={classes['faq__feedback-link']}
                    href={`mailto:${intl.formatMessage({
                        defaultMessage: 'info@wattattack.com',
                        id: 'Help_jy8dns',
                        description: 'блок частые вопросы'
                    })}`}
                >
                    <Typography variant="subtitle1" component="p">
                        <FormattedMessage
                            defaultMessage="info@wattattack.com"
                            id="Help_jy8dns"
                            description="блок частые вопросы"
                        />
                    </Typography>
                </a>
            </CustomContainer>
        </section>
    );
}

export default FAQ;
