import Typography from '@mui/material/Typography';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../MainHeader/MainHeader';
import MainFooter from '../../MainFooter/MainFooter';
import classes from './PrivacyPolicy.module.css';
import Container from '@mui/material/Container';

function PrivacyPolicyPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <section
                    className={classes['privacy-policy-page']}
                    aria-labelledby="privacy-policy-title"
                >
                    <Container sx={{display: 'grid', justifyItems: 'start'}}>
                        <Typography variant="h1" color="primary" id="privacy-policy-title">
                            Политика конфиденциальности
                        </Typography>
                        <b>Дата вступления в силу: 01.01.2024</b>
                        <p>
                            Мы уважаем вашу конфиденциальность и стремимся защитить ваши личные
                            данные. Настоящая Политика конфиденциальности объясняет, как мы
                            собираем, используем и защищаем информацию о вас в нашем приложении.
                        </p>
                        <Typography variant="h2">1. Определение понятий</Typography>
                        <p>
                            <b>«Закон»</b> означает Федеральный закон Российской Федерации «О
                            персональных данных» со всеми изменениями и дополнениями, а также иные
                            законодательные акты Российской Федерации. «Приложение» это программное
                            обеспечение (со всеми существующими дополнениями и улучшениями),
                            предназначенное для работы на смартфонах, планшетах и персональных
                            компьютерах. Для целей настоящей Политики под приложением
                            подразумевается следующее программное обеспечение: WattAttack.
                            «Персональные данные» означает совокупность личных данных и/или
                            неперсонифицированной информации о Пользователе, предоставляемую им
                            самим Правообладателю и/или автоматически собираемую Правообладателем
                            и/или третьими лицами.
                        </p>
                        <p>
                            <b>«Политика»</b> означает настоящую Политику конфиденциальности приложения (со всеми существующими дополнениями и изменениями).
                        </p>
                        <p>
                            <b>«Пользователь»</b> означает юридическое или физическое лицо, которое загрузило
                            приложение на смартфон, планшет и или компьютер и/или осуществило активацию
                            такого приложения на одном из указанных устройств, а также посетило сайт
                            приложения по адресам на домене wattattack.com. «Пользовательское
                            соглашение» означает соглашение, заключаемое между Правообладателем и
                            Пользователем в отношении порядка, правил и особенностей использования
                            Пользователем приложения и сайта. Пользователь присоединяется к такому
                            соглашению и не имеет права вносить и/или требовать внесения в него
                            каких-либо изменений или дополнений. «Правообладатель» означает следующее
                            лицо, которому принадлежат исключительные права владения приложением: ООО
                            «Ваттаттак», юридический адрес: 432048, Ульяновская область, г. Ульяновск,
                            Университетская Набережная ул, д. 13, кв. 68, info@wattattack.com. «Файлы
                            куки» означает небольшие файлы, отправляемые каким-либо приложениям или
                            сайтом, и размещаемые на смартфонах, планшетах и других устройствах
                            Пользователя, для улучшения работы таких приложений или сайтов, а также
                            качества размещенного в них контента. «Подписка» предоставление Пользователю
                            на условиях Договора возможности за плату в течение ограниченного промежутка
                            времени получить определенное условиями подписки количества Услуг.
                        </p>
                        <Typography variant="h2">2. Права пользователя по защите персональных данных</Typography>
                        <p>
                            В связи с предоставлением Персональных данных Пользователь автоматически получает следующие права:
                            получать данные, касающиеся их обработки (основания и цели такой обработки,
                            применяемые способы обработки, сведения о лицах, которые имеют доступ к ним
                            или которым они могут быть раскрыты на основании договора или Закона).
                            получать данные о месте нахождения и идентификационных данных лиц,
                            совершающих обработку Персональных данных. получать данные о сроках хранения
                            Персональных данных. получать данные об осуществленной или о предполагаемой
                            трансграничной передаче Персональных данных. обжаловать действия или
                            бездействие Правообладателя в уполномоченный орган по защите прав субъектов
                            персональных данных или в судебном порядке. получать возмещение убытков
                            и/или компенсацию морального вреда в судебном порядке в следствие допущенных
                            Правообладателем и/или третьими лицами нарушений прав Пользователя на охрану
                            и защиту его Персональных данных. реализовывать иные права в области защиты
                            персональных данных, предусмотренные Законом или положениями данной
                            Политики.
                        </p>
                        <Typography variant="h2">3. Перечень собираемых данных</Typography>
                        <p>
                            <b>Неперсонифицированная информация о пользователях</b><br />
                            В связи с использованием приложения Правообладатель может автоматически собирать и обрабатывать следующую неперсонифицированную
                            информацию о Пользователе: информацию о трафике, возможном количестве
                            совершенных кликов, логи и другие данные; информацию о месте нахождении
                            Пользователя (геолокация) включена в функциональность приложения, но данные
                            геолокации не сохраняются приложением и не передаются; информацию об
                            устройстве, с которого выполняется вход, операционная система, платформа,
                            прочая техническая информация (размер экрана, операционная система и
                            подобное).
                        </p>
                        <p>
                            <b>Личные данные о пользователях</b><br />
                            Пользователь также даёт согласие на передачу персональной информации Правообладателю для целей контроля качества
                            оказываемых услуг, а также для обработки запросов и претензий Пользователя,
                            связанных с работой Сервиса и/или приложения. Правообладатель вправе
                            использовать данные, содержащие обезличенную персональную информацию
                            Пользователей, в том числе осуществлять передачу таких данных третьим лицам
                            для создания и обработки статистических данных, улучшения качества оказания
                            услуг и качества Сервиса и др.
                        </p>
                        <Typography variant="h2">4. Цели сбора и обработки персональных данных</Typography>
                        <p>
                            Для оперативной и корректной работы приложения, улучшения функционирования
                            работы приложения, улучшения контента приложения, улучшения внутренней
                            архитектуры и функциональности приложения. для идентификации Пользователя.
                            для соблюдения требований Закона. для технической поддержки приложения,
                            выявления проблем в его работе и их устранение. для выполнения иных
                            обязательств Правообладателя, которые возникли перед Пользователем. для
                            любых других целей, при условии получения отдельного согласия от
                            Пользователя.
                        </p>
                        <p>
                            Обработка Персональных данных осуществляется на основе принципов: (1)
                            законности целей и способов обработки; и (2) добросовестности; и (3)
                            соответствия целей обработки Персональных данных целям, заранее определенным
                            и заявленным при сборе таких Персональных данных; и (4) соответствия объема и
                            характера обрабатываемых Персональных данных заявленным целям их обработки.
                        </p>
                        <Typography variant="h2">5. Условия обработки персональных данных</Typography>
                        <p>
                            Обработка Персональных данных проводится в случаях получения согласия
                            от Пользователя. Правообладатель обязуется обрабатывать персональную
                            информацию Пользователя, полученную им в рамках оказания Услуг по Подписке,
                            в соответствии с требованиями Федерального закона «О персональных данных» от
                            27.07.2006 № 152-ФЗ. Обработка персональной информации Пользователя
                            ограничена целями оказания Услуг по Подписке. Правообладатель вправе
                            передать персональную информацию третьим лицам только в случае, если такая
                            передача необходима для оказания Услуг, в том числе при участии или
                            привлечении третьих лиц к выполнению обязанностей перед пользователем. В
                            случае обезличивания Персональных данных, что не позволяет прямо или
                            опосредованно определить Пользователя, последующее использование и раскрытие
                            таких данных третьим лицам допускается и в отношении их более не применяются
                            правила данной Политики. Правообладатель принимает все возможные меры для
                            защиты конфиденциальности полученных Персональных данных, за исключением
                            случаев, когда Пользователь сделал такие данные общедоступными. Обработка
                            Персональных данных осуществляется с использованием средств автоматизации и
                            без использования таких средств автоматизации.
                        </p>
                        <Typography variant="h2">6. Направление жалоб и запросов правообладателю</Typography>
                        <p>
                            Жалобы, требования и запросы направляются Правообладателю на адрес электронной почты <a href='mailto:info@wattattack.com'>info@wattattack.com</a>
                        </p>
                        <Typography variant="h2">7. Сроки и порядок хранения персональных данных</Typography>
                        <p>
                            Хранение осуществляется самостоятельно Правообладателем. Хранение осуществляется в течение всего срока использования Пользователем данного приложения.
                        </p>
                        <Typography variant="h2">8. Порядок защиты персональных данных</Typography>
                        <p>
                            Защита конфиденциальности Персональных данных является первостепенной и важной задачей для Правообладателя. Правообладатель придерживается всех требуемых международных стандартов, правил и рекомендаций по защите Персональных данных.
                        </p>
                        <Typography variant="h2">Заключение</Typography>
                        <p>
                            Используя наше приложение, вы соглашаетесь с условиями этой Политики конфиденциальности.
                            Если вы не согласны с любым из условий, пожалуйста, не используйте наше приложение.
                        </p>
                        <p>
                            <b>Дата последнего обновления: 01.07.2024</b><br />
                            Эта Политика конфиденциальности была создана для обеспечения прозрачности в отношении того, как мы обрабатываем и защищаем ваши данные. Мы стремимся к тому, чтобы ваше взаимодействие с нашим приложением было безопасным и конфиденциальным.
                        </p>
                    </Container>
                </section>
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default PrivacyPolicyPage;
