import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useStores } from '../../../../root-store-context';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CabinetSettingsAthleteProfileForm from './CabinetSettingsAthleteProfileForm/CabinetSettingsAthleteProfileForm';
import CabinetSettingsUserProfileForm from './CabinetSettingsUserProfileForm/CabinetSettingsUserProfileForm';
import CabinetSettingsUserEmailForm from './CabinetSettingsUserEmailForm/CabinetSettingsUserEmailForm';
import CabinetSettingsUserPasswordForm from './CabinetSettingsUserPasswordForm/CabinetUserPasswordForm';
import CabinetSettingsSubscriptionStatus from './CabinetSettingsSubscriptionStatus/CabinetSettingsSubscriptionStatus';
import CabinetSettingsConnections from './CabinetSettingsConnections/CabinetSettingsConnections';
import CabinetUserSetPasswordForm from './CabinetUserSetPasswordForm/CabinetUserSetPasswordForm';
import classes from './CabinetSettings.module.css';

const CabinetSettings = () => {
    const { user } = useStores();
    const [isEmailEditorMode, setIsEmailEditorMode] = useState<boolean>(false);
    const [isPasswordEditorMode, setIsPasswordEditorMode] = useState<boolean>(false);
    const [isSetPasswordShowed, setIsSetPasswordShowed] = useState<boolean>(
        user.data?.needsPasswordReset === true
    );

    const handleEmailEditClick = () => {
        setIsEmailEditorMode((prev) => !prev);
    };

    const handlePasswordEditClick = () => {
        setIsPasswordEditorMode((prev) => !prev);
    };

    return (
        <div className={classes['cabinet-settings']}>
            <section
                className={classes['cabinet-settings__section']}
                aria-labelledby="subscription_title"
            >
                <Typography
                    variant="h3"
                    component="h2"
                    className={classes['cabinet-settings__title']}
                    id="subscription_title"
                >
                    <FormattedMessage
                        defaultMessage="Подписка"
                        id="Cabinet_SubscriptionSectionTitle"
                        description="кабинет"
                    />
                </Typography>
                <CabinetSettingsSubscriptionStatus />
            </section>

            <section
                className={classes['cabinet-settings__section']}
                aria-labelledby="account_title"
            >
                <Typography
                    variant="h3"
                    component="h2"
                    className={classes['cabinet-settings__title']}
                    id="account_title"
                >
                    <FormattedMessage
                        defaultMessage="Учётная запись"
                        id="Cabinet_AccountSectionTitle"
                        description="Кабинет"
                    />
                </Typography>
                <p className={classes['cabinet-settings__text']}>
                    <FormattedMessage
                        defaultMessage="Здесь вы можете поменять адрес электронной почты (который также является логином) и пароль."
                        id="Cabinet_AccountSectionDesc"
                        description="Кабинет"
                    />
                </p>
                <div className={classes['cabinet-settings__forms']}>
                    {isEmailEditorMode && (
                        <CabinetSettingsUserEmailForm setIsEditorMode={setIsEmailEditorMode} />
                    )}
                    {isPasswordEditorMode && (
                        <CabinetSettingsUserPasswordForm
                            setIsEditorMode={setIsPasswordEditorMode}
                        />
                    )}
                    {isSetPasswordShowed && (
                        <CabinetUserSetPasswordForm setIsEditorMode={setIsSetPasswordShowed} />
                    )}
                    <div
                        className={`${classes['cabinet-settings__buttons']} ${classes['cabinet-settings__buttons--offset']}`}
                    >
                        {!isEmailEditorMode && (
                            <Button
                                type="button"
                                onClick={handleEmailEditClick}
                                className={classes['cabinet-settings__button']}
                                variant="contained"
                            >
                                <FormattedMessage
                                    defaultMessage="Изменить email"
                                    id="Cabinet_EmailChange"
                                    description="кабинет"
                                />
                            </Button>
                        )}

                        {!isPasswordEditorMode && !isSetPasswordShowed && (
                            <Button
                                type="button"
                                onClick={handlePasswordEditClick}
                                className={classes['cabinet-settings__button']}
                                variant="contained"
                            >
                                <FormattedMessage
                                    defaultMessage="Изменить пароль"
                                    id="Cabinet_PasswordChangeChange"
                                    description="кабинет"
                                />
                            </Button>
                        )}
                    </div>
                </div>
            </section>
            <section
                className={classes['cabinet-settings__section']}
                aria-labelledby="personal-data_title"
            >
                <Typography
                    variant="h3"
                    component="h2"
                    className={classes['cabinet-settings__title']}
                    id="personal-data_title"
                >
                    <FormattedMessage
                        defaultMessage="Личные данные"
                        id="Cabinet_PersonalSectionTitle"
                        description="Кабинет"
                    />
                </Typography>
                <p className={classes['cabinet-settings__text']}>
                    <FormattedMessage
                        defaultMessage="Настройки пользователя в игре. Вы можете их поменять также на странице пользователя в приложении WattAttack."
                        id="Cabinet_PersonalSectionDesc"
                        description="Кабинет"
                    />
                </p>
                <div className={classes['cabinet-settings__forms']}>
                    <CabinetSettingsUserProfileForm />
                    <CabinetSettingsAthleteProfileForm />
                </div>
            </section>
            <section className={classes['cabinet-settings__section']} aria-labelledby='connections_title'>
                <Typography
                    variant="h3"
                    component="h2"
                    className={classes['cabinet-settings__title']}
                    id="connections_title"
                >
                    <FormattedMessage
                        defaultMessage="Подключённые сервисы"
                        id="Cabinet_ConnectionsSectionTitle"
                        description="Кабинет"
                    />
                </Typography>
                <CabinetSettingsConnections />
            </section>
        </div>
    );
};

export default observer(CabinetSettings);
