import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useStores } from '../../../../root-store-context';
import useLocale from '../../../../CustomHooks/useLocale';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_ROUTES } from '../../../../const';

import classes from './CabinetActivities.module.css';
import CabinetSettingsConnections from '../CabinetSettings/CabinetSettingsConnections/CabinetSettingsConnections';

type Activity = {
    id: number;
}

function CabinetActivities() {
    const [ file, setFile ] = useState<File>();
    const [ activities, setActivities ] = useState<Activity[]>([]);
    const { user } = useStores();
    const navigate = useNavigate();
    const { localeUrlPrefix } = useLocale();

    useEffect(() => {
        if (!user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, []);

    useEffect(() => {
        fetch('/api/v1/activities', {
            method: 'GET'
        })
        .then(async (ret) => {
            const data = await ret.json();

            if (data.activities) {
                setActivities(data.activities);
            }
        })
        .catch(e => {
            console.error(e);
        });
    }, [])

    const handleFileChange = (e: BaseSyntheticEvent) => {
        setFile(e.target.files[0]);
    };

    const handleUploadClick = (e: BaseSyntheticEvent) => {
        if (!file) {
            return;
        }

        const formData = new FormData();

        formData.append('fitFile', file);

        fetch('/api/v1/activity/upload', {
            method: 'POST',
            body: formData
        })
        .then(async (ret) => {
            const data = await ret.json();

            if (data.activity) {
                setActivities([
                    data.activity,
                    ...activities
                ]);
            }
        })
        .catch(e => {
            console.error(e);
        });
    };

    return (
        <div className={classes['cabinet-activities']}>
            <h1>Activities</h1>
            
            <h2>Создать</h2>
            <TextField type="file" onChange={handleFileChange} />
            <Button variant="contained" color="primary" onClick={handleUploadClick}>
                Upload
            </Button>
            <h2>Мои активности</h2>
            <div>{ JSON.stringify(activities, null, 4) }</div>

            <h2>Подключённые приложения</h2>
            <CabinetSettingsConnections />
        </div>
    );
}

export default observer(CabinetActivities);
