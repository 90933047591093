import { useEffect, useState } from 'react';
import { useStores } from '../../../../../root-store-context';
import useLocale from '../../../../../CustomHooks/useLocale';
import { useAlertContext } from '../../../Context/AlertContext';
import { format } from 'date-fns';
import { ru, enGB } from 'date-fns/locale';
import { Typography, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import CabinetSettingsSubscriptionStatusNotConfirmed from '../CabinetSettingsSubscriptionStatusNotConfirmed/CabinetSettingsSubscriptionStatusNotConfirmed';
import CabinetSubscriptionForm from '../CabinetSettingsSubscriptionForm/CabinetSettingsSubscriptionForm';
import classes from '../CabinetSettings.module.css';

function CabinetSettingsSubscriptionStatus() {
    const { user, subscription } = useStores();
    const userData = user.data;
    const subscriptionData = subscription?.data;
    const [isLoading, setIsLoading] = useState(true);
    const intl = useIntl();
    const showAlert = useAlertContext();
    const { locale } = useLocale();
    const theme = useTheme();

    useEffect(() => {
        if (!subscriptionData) {
            subscription
                .get()
                .then(() => setIsLoading(false))
                .catch((error) => {
                    setIsLoading(false);
                    showAlert(
                        `${intl.formatMessage({
                            defaultMessage: 'Произошла ошибка:',
                            id: 'Cabinet_ErrorMessage',
                            description: 'кабинет'
                        })} ${error}`,
                        error
                    );
                });
        } else {
            setIsLoading(false);
        }
    }, [subscriptionData, subscription]);

    const subscriptionEndDate = subscriptionData?.activeUntil
        ? format(new Date(subscriptionData.activeUntil), 'PPP', {
              locale: locale === 'ru' ? ru : enGB
          })
        : undefined;

    let subscriptionStatus;

    if (!userData?.isActivated) {
        subscriptionStatus = intl.formatMessage({
            defaultMessage: 'ожидает активации',
            id: 'Cabinet_SubscriptionNotActivatedMessage',
            description: 'кабинет'
        });
    } else if (subscriptionData?.isTrial) {
        subscriptionStatus = intl.formatMessage({
            defaultMessage: 'пробный период',
            id: 'Cabinet_SubscriptionTrial',
            description: 'кабинет'
        });
    } else if (subscriptionData?.isActive) {
        subscriptionStatus = intl.formatMessage({
            defaultMessage: 'активна',
            id: 'Cabinet_SubscriptionActive',
            description: 'кабинет'
        });
    } else {
        subscriptionStatus = intl.formatMessage({
            defaultMessage: 'не активна',
            id: 'Cabinet_SubscriptionNotActive',
            description: 'кабинет'
        });
    }

    if (isLoading) {
        return (
            <div>
                <Typography>
                    <FormattedMessage
                        defaultMessage="Загрузка данных о подписке"
                        id="Cabinet_SubscriptionDataLoad"
                        description="кабинет"
                    />
                </Typography>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <div>
                <p className={classes['cabinet-settings__text']}>
                    <FormattedMessage
                        defaultMessage="Состояние подписки:"
                        id="Cabinet_SubscriptionStatus"
                        description="кабинет"
                    />{' '}
                    <b
                        style={{
                            color: !userData?.isActivated ? theme.palette.error.dark : 'initial'
                        }}
                    >
                        {subscriptionStatus}
                    </b>
                </p>

                {!userData?.isActivated && (
                    <p className={classes['cabinet-settings__text']}>
                        <FormattedMessage
                            defaultMessage="Для активации пробного периода на 30 дней подтвердите ваш email, пройдя по ссылке из письма."
                            id="Cabinet_SubscriptionConfirmEmail"
                            description="кабинет"
                        />
                    </p>
                )}
                {subscriptionData?.isActive && (
                    <p className={classes['cabinet-settings__text']}>
                        <FormattedMessage
                            defaultMessage="Минимальная цена за месяц использования приложения составляет 1 рубль, но вы можете указать любую иную стоимость, если оцениваете эту подписку дороже и хотите поддержать разработчиков проекта. Автопролонгации и автосписания средств нет. В дальнейшем мы планируем ввести гибкие тарифные планы."
                            id="Cabinet_SubscriptionRenewal"
                            description="кабинет"
                        />
                    </p>
                )}
                {!subscriptionData?.isActive && userData?.isActivated && (
                    <p className={classes['cabinet-settings__text']}>
                        <FormattedMessage
                            defaultMessage="Минимальная цена за месяц использования приложения составляет 1 рубль, но вы можете указать любую иную стоимость, если оцениваете эту подписку дороже и хотите поддержать разработчиков проекта. Автопролонгации и автосписания средств нет. В дальнейшем мы планируем ввести гибкие тарифные планы."
                            id="Cabinet_SubscriptionPayment"
                            description="кабинет"
                        />
                    </p>
                )}
            </div>

            {!userData?.isActivated && <CabinetSettingsSubscriptionStatusNotConfirmed />}
            {subscriptionData?.isActive && (
                <p className={classes['cabinet-settings__text']}>
                    <FormattedMessage
                        defaultMessage="Подписка активна до:"
                        id="Cabinet_SubscriptionPeriod"
                        description="кабинет"
                    />{' '}
                    <b>{subscriptionEndDate}</b>
                </p>
            )}
            {!subscriptionData?.isActive && userData?.isActivated && (
                <div className={classes['cabinet-settings__forms']}>
                    <CabinetSubscriptionForm />
                </div>
            )}
        </>
    );
}

export default CabinetSettingsSubscriptionStatus;
