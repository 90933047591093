import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Telegram from '@mui/icons-material/Telegram';
import classes from './DownloadPlatforms.module.css';

function DownloadMac() {
    return (
        <>
            <p>
                <FormattedMessage
                    defaultMessage="Версия для MacOS находится в разработке. Следите за новостями обновления в нашем Telegram канале."
                    id="downloadAppMacOSMessage"
                    description="скачать"
                />
            </p>
            <div className={classes['download__subscription']}>
                {/* <TextField label="E-mail" type="email" size="medium" /> */}
                {/* <Button variant="contained" color="info" size="large">
                    <FormattedMessage
                        defaultMessage="Известить меня"
                        id="downloadAppNotify"
                        description="скачать"
                    />
                </Button> */}
                <Button
                    href="https://t.me/wattattack"
                    variant="contained"
                    color="info"
                    size="large"
                    startIcon={<Telegram />}
                >
                    <FormattedMessage
                        defaultMessage="Телеграм"
                        id="downloadAppTelegram"
                        description="скачать"
                    />
                </Button>
            </div>
        </>
    );
}

export default DownloadMac;
