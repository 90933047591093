import * as yup from 'yup';
import useIntl from 'react-intl/src/components/useIntl';

export function useValidationSchema() {
    const intl = useIntl();
    return {
        passwordSchema: yup
            .string()
            .min(
                6,
                intl.formatMessage({
                    defaultMessage: 'Пароль должен быть длиннее 6 символов',
                    id: 'Register_xwo134g',
                    description: 'форма регистрации'
                })
            )
            .max(
                30,
                intl.formatMessage({
                    defaultMessage: 'Пароль не должен быть длиннее 30 символов',
                    id: 'Register_xwo234g',
                    description: 'форма регистрации'
                })
            )
            .matches(
                /[0-9]/,
                intl.formatMessage({
                    defaultMessage:
                        'Пароль должен содержать хотя бы одну цифру, строчную и заглавную букву',
                    id: 'Register_xwo334g',
                    description: 'форма регистрации'
                })
            )
            .matches(
                /[a-z]/,
                intl.formatMessage({
                    defaultMessage:
                        'Пароль должен содержать хотя бы одну цифру, строчную и заглавную букву',
                    id: 'Register_xwo334g',
                    description: 'форма регистрации'
                })
            )
            .matches(
                /[A-Z]/,
                intl.formatMessage({
                    defaultMessage:
                        'Пароль должен содержать хотя бы одну цифру, строчную и заглавную букву',
                    id: 'Register_xwo334g',
                    description: 'форма регистрации'
                })
            )
            .required(
                intl.formatMessage({
                    defaultMessage: 'Пожалуйста, введите пароль',
                    id: 'Register_D8GBRt',
                    description: 'форма регистрации'
                })
            ),

        confirmPasswordSchema: yup
            .string()
            .required(
                intl.formatMessage({
                    defaultMessage: 'Пожалуйста, подтвердите пароль',
                    id: 'Register_obqlF7',
                    description: 'форма регистрации'
                })
            )
            .oneOf(
                [yup.ref('password')],
                intl.formatMessage({
                    defaultMessage: 'Пароли не совпадают. Попробуйте еще раз',
                    id: 'Register_n0TFir',
                    description: 'форма регистрации'
                })
            ),

        currentPasswordSchema: yup
            .string()
            .max(30)
            .required(
                intl.formatMessage({
                    defaultMessage: 'Введите пароль',
                    id: 'Login_uWLBDI',
                    description: 'форма входа'
                })
            ),

        emailSchema: yup
            .string()
            .email(
                intl.formatMessage({
                    defaultMessage: 'Введите корректный e-mail',
                    id: 'Login_vfBnVw',
                    description: 'форма входа'
                })
            )
            .required(
                intl.formatMessage({
                    defaultMessage: 'Введите e-mail',
                    id: 'Login_85YJwN',
                    description: 'форма входа'
                })
            )
            .max(
                256,
                intl.formatMessage({
                    defaultMessage: 'Не более 256 символов',
                    id: 'Cabinet_ErrorSubscriptionMessage',
                    description: 'форма входа'
                })
            ),

        firstNameSchema: yup
            .string()
            .required(
                intl.formatMessage({
                    defaultMessage: 'Введите имя',
                    id: 'Register_4OlRbZ',
                    description: 'форма регистрации'
                })
            )
            .min(1)
            .max(30),

        lastNameSchema: yup.string().min(1).max(30),

        approvalSchema: yup.boolean().oneOf(
            [true],
            intl.formatMessage({
                defaultMessage:
                    'Для регистрации необходимо согласиться на обработку персональных данных',
                id: 'Register_jS2501E',
                description: 'форма регистрации'
            })
        ),

        birthDateSchema: yup
            .date()
            .nullable()
            .max(
                new Date(),
                intl.formatMessage({
                    defaultMessage: 'Дата рождения не может быть в будущем',
                    id: 'Cabinet_AthleteBirthDateFutureError',
                    description: 'кабинет'
                })
            )
            .test(
                'age',
                intl.formatMessage({
                    defaultMessage: 'Мы принимаем пользователей не моложе 10 лет.',
                    id: 'Cabinet_AthleteBirthDateAgeError',
                    description: 'кабинет'
                }),
                (value) => {
                    if (!value) return true;
                    return (
                        Date.now() - new Date(value).valueOf() > (10 * 365 + 2.5) * 24 * 3600 * 1000
                    );
                }
            ),

        heightSchema: yup
            .string()
            .test(
                'is-number',
                intl.formatMessage({
                    defaultMessage: 'Значение должно быть числовым',
                    id: 'Cabinet_ValidationIsNumber',
                    description: 'кабинет'
                }),
                (value) => {
                    if (!value) return true;
                    return !isNaN(Number(value));
                }
            )
            .test(
                'is-between-100-and-250',
                intl.formatMessage({
                    defaultMessage: 'Значение должно быть не менее 100 и не более 250',
                    id: 'Cabinet_ValidationHeightRange',
                    description: 'кабинет'
                }),
                (value) => {
                    if (!value) return true;
                    const parsedValue = Number(value);
                    return parsedValue >= 100 && parsedValue <= 250;
                }
            ),

        weightSchema: yup
            .string()
            .test(
                'is-number',
                intl.formatMessage({
                    defaultMessage: 'Значение должно быть числовым',
                    id: 'Cabinet_ValidationIsNumber',
                    description: 'кабинет'
                }),
                (value) => {
                    if (!value) return true;
                    return !isNaN(Number(value));
                }
            )
            .test(
                'is-between-38-and-500',
                intl.formatMessage({
                    defaultMessage: 'Значение должно быть не менее 38 и не более 300',
                    id: 'Cabinet_ValidationWeightRange',
                    description: 'кабинет'
                }),
                (value) => {
                    if (!value) return true;
                    const parsedValue = Number(value);
                    return parsedValue >= 38 && parsedValue <= 300;
                }
            ),

        textSchema: yup
            .string()
            .required(
                intl.formatMessage({
                    defaultMessage: 'Опишите ваш вопрос',
                    id: 'SendQuestion_X1INuw',
                    description: 'форма обратной связи'
                })
            )
            .max(
                500,
                intl.formatMessage({
                    defaultMessage: 'Не более 500 символов',
                    id: 'SendQuestion_X1INuw',
                    description: 'форма обратной связи'
                })
            ),

        amountSchema: yup
            .number()
            .required(
                intl.formatMessage({
                    defaultMessage: 'Поле не может быть пустым',
                    id: 'Cabinet_ErrorSubscriptionAmount',
                    description: 'кабинет'
                })
            )
            .integer(
                intl.formatMessage({
                    defaultMessage: 'Введите целое число',
                    id: 'Cabinet_ErrorSubscriptionInteger',
                    description: 'кабинет'
                })
            )
            .min(
                1,
                intl.formatMessage({
                    defaultMessage: 'Введите число от 1 до 10 000',
                    id: 'Cabinet_ErrorSubscriptionRange',
                    description: 'кабинет'
                })
            )
            .max(
                10000,
                intl.formatMessage({
                    defaultMessage: 'Введите число от 1 до 10 000',
                    id: 'Cabinet_ErrorSubscriptionRange',
                    description: 'кабинет'
                })
            ),
        messageToDevelopersSchema: yup.string().max(
            256,
            intl.formatMessage({
                defaultMessage: 'Не более 256 символов',
                id: 'Cabinet_ErrorSubscriptionMessage',
                description: 'кабинет'
            })
        )
    };
}
