import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import { Button, Paper } from '@mui/material';
import classes from './Subscription.module.css';

function Subscription() {
    const intl = useIntl();
    const [href, setHref] = useState('login');

    useEffect(() => {
        if (localStorage.jwtToken !== undefined) {
            setHref('cabinet');
        }
    }, []);

    return (
        <section
            id="subscription"
            className={classes['subscription']}
            aria-labelledby="subscription_title"
        >
            <CustomContainer>
                <Typography
                    variant="h2"
                    color="primary"
                    component="h2"
                    className={classes['subscription__title']}
                    id="subscription_title"
                >
                    <FormattedMessage
                        defaultMessage="Подписка"
                        id="Rates_PUE4iO"
                        description="тарифы"
                    />
                </Typography>
                <div className={classes['subscription__plans']}>
                    <Paper elevation={8} className={classes['subscription__declaration']}>
                        <Typography
                            variant="h3"
                            color="primary"
                            component="h3"
                            className={classes['subscription__subtitle']}
                        >
                            <FormattedMessage
                                defaultMessage="Пробный период длится 30 дней"
                                id="Rates_hyJFMn"
                                description="тарифы"
                            />
                        </Typography>
                        {intl.locale === 'ru' ? (
                            <>
                                <p>
                                    После окончания пробного периода вы можете купить месячную
                                    подписку на WattAttack по свободной цене от 1 рубля. Если
                                    вы хотите поддержать проект, то можете при покупке
                                    самостоятельно указать цену выше. Подписка без автопродления и
                                    автосписания! В дальнейшем мы планируем ввести гибкие тарифные
                                    планы.
                                </p>
                                <Button
                                    className={classes['subscription__plan-button']}
                                    variant="contained"
                                    color="info"
                                    size="large"
                                    href={href}
                                >
                                    <FormattedMessage
                                        defaultMessage="Купить за 1 рубль"
                                        id="Rates_LQnRQz"
                                        description="тарифы"
                                    />
                                </Button>
                            </>
                        ) : (
                            <p>
                                Please contact{' '}
                                <a
                                    href="mailto:info@wattattack.com"
                                    className={classes['subscription__email']}
                                >
                                    info@wattattack.com
                                </a>{' '}
                                to extend your trial period.
                            </p>
                        )}
                    </Paper>
                </div>
            </CustomContainer>
        </section>
    );
}

export default Subscription;
