export const APP_DOWNLOAD_LINK = 'https://download.wattattack.ru/release/WattAttack_668.exe';
export const CURRENT_APP_VERSION = {
    windows: { version: '6.68', size: '2,7' },
    macos: { version: '0', size: '0' },
    ios: { version: '0', size: '0' },
    android: { version: '0', size: '0' }
} as const;

export const NAVIGATION_ROUTES = {
    root: '/',
    login: '/login',
    logout: '/logout',
    cabinet: '/cabinet',
    registration: '/registration',
    oauth: '/oauth',
    password_recovery: '/user/recovery',
    password_reset: '/user/:id/change/:token',
    how_to_start: '/#howToStart',
    maps: '/#maps',
    workouts: '/#workouts',
    about: '/#about',
    subscription: '/#subscription',
    help: '/#faq',
    download: '/#download',
    user_activate: '/user/activate',
    activities: '/cabinet/activities',
    privacy_policy: '/privacy-policy'
} as const;

export const DEFAULT_LOCALE = 'ru';
export const SUPPORTED_LOCALES = ['ru', 'en'];
export const LOCALE_COOKIE_NAME = 'locale';

export const DEFAULT_LOGO_SIZE = {
    width: '487',
    height: '85'
} as const;

export const OAUTH_PROVIDERS = {
    vk: 'vk',
    mailRu: 'mailRu',
    google: 'google'
} as const;

export type OAuthProviders = keyof typeof OAUTH_PROVIDERS;

export const MESSAGE_TRANSITION_TIMEOUT = 500;
