import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { useValidationSchema } from '../../../../../CustomHooks/useValidationSchema';

import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { useStores } from '../../../../../root-store-context';
import { useState } from 'react';
import { useAlertContext } from '../../../Context/AlertContext';
import CircularProgressWrapper from '../../../CircularProgressWrapper/CircularProgressWrapper';
import TextFieldWrapper from '../../../TextFieldWrapper/TextFieldWrapper';
import classes from '../CabinetSettings.module.css';

function CabinetSettingsSubscriptionForm() {
    const intl = useIntl();
    const { subscription } = useStores();
    const showAlert = useAlertContext();
    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    const { amountSchema, messageToDevelopersSchema } = useValidationSchema();

    const formik = useFormik({
        initialValues: {
            amount: '1',
            message: ''
        },
        onSubmit: async (values) => {
            await subscription.create({
                amount: Number(values.amount),
                message: values.message || undefined
            });

            const paymentUrl = subscription.data?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert(
                    intl.formatMessage({
                        defaultMessage: 'Произошла ошибка',
                        id: 'Cabinet_PersonalChangeError',
                        description: 'кабинет'
                    }),
                    'error'
                );
            }
        },
        validationSchema: yup.object({
            amount: amountSchema,
            message: messageToDevelopersSchema
        })
    });

    const { isSubmitting } = formik;

    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                className={classes['cabinet-settings__form']}
                aria-label={intl.formatMessage({
                    defaultMessage: 'Форма оплаты подписки',
                    id: 'Cabinet_subscriptionPaymentFormTitle',
                    description: 'кабинет'
                })}
            >
                <Typography
                    variant="h4"
                    component="h3"
                    className={classes['cabinet-settings__subtitle']}
                >
                    <FormattedMessage
                        defaultMessage="Купить месяц WattAttack"
                        id="Cabinet_SubscribeFormTitle"
                        description="кабинет"
                    />
                </Typography>
                <TextFieldWrapper
                    errorText={formik.errors.amount}
                    errorId="amountError"
                    showErrors={showErrors}
                >
                    <TextField
                        label={intl.formatMessage({
                            defaultMessage: 'Стоимость',
                            id: 'Cabinet_SubscriptionCost',
                            description: 'кабинет'
                        })}
                        name="amount"
                        value={formik.values.amount}
                        inputProps={{
                            inputMode: 'numeric',
                            'aria-describedby': 'amountError'
                        }}
                        error={!!formik.errors.amount}
                        onChange={(event) => {
                            const filteredValue = event.target.value.replace(/[^0-9]/g, '');
                            formik.setFieldValue('amount', filteredValue);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FormattedMessage
                                        defaultMessage="руб."
                                        id="Cabinet_SubscriptionCurrency"
                                        description="кабинет"
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </TextFieldWrapper>

                <TextFieldWrapper
                    errorText={formik.errors.message}
                    errorId="messageError"
                    showErrors={showErrors}
                >
                    <TextField
                        label={intl.formatMessage({
                            defaultMessage: 'Сообщение разработчикам (необязательно)',
                            id: 'Cabinet_SubscriptionFeedback',
                            description: 'кабинет'
                        })}
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        error={!!formik.errors.message}
                        multiline
                        rows={4}
                        inputProps={{
                            'aria-describedby': 'messageError'
                        }}
                    />
                </TextFieldWrapper>

                <CircularProgressWrapper isSubmitting={isSubmitting} fullWidth justify="start">
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        className={`${classes['cabinet-settings__button']} ${classes['cabinet-settings__button--buy']}`}
                        onClick={handleClickShowErrors}
                        disabled={isSubmitting}
                    >
                        <FormattedMessage
                            defaultMessage="Купить"
                            id="Cabinet_SubscriptionButton"
                            description="кабинет"
                        />
                    </Button>
                </CircularProgressWrapper>
            </form>
        </>
    );
}

export default observer(CabinetSettingsSubscriptionForm);
