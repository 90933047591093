import { buildApiUrl } from './api';

export type Subscription = {
    activeUntil?: string;
    isActive: boolean;
    isTrial?: boolean;
    paymentUrl?: string;
};

type ErrorResponse = {
    code: string;
};

type FaultResponse = {
    message: string;
    errors: ErrorResponse[];
};

type SuccessfulGetBody = {
    subscription: Subscription;
};

type SuccessfulCreateBody = {
    subscription: Subscription;
};

type BadRequestBody = FaultResponse;

type SubscriptionServiceGetResponse = Omit<Response, 'json'> & {
    status: 200;
    json: () => SuccessfulGetBody | PromiseLike<SuccessfulGetBody>;
};

type SubscriptionServiceCreateResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulCreateBody | PromiseLike<SuccessfulCreateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

export type SubscriptionServiceCreateRequest = {
    amount: number;
    message?: string;
};

class SubscriptionService {
    static async get() {
        const response = (await fetch(buildApiUrl('/subscription'), {
            method: 'GET'
        })) as SubscriptionServiceGetResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async create(data: SubscriptionServiceCreateRequest) {
        const response = (await fetch(buildApiUrl('/subscription/create'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as SubscriptionServiceCreateResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }
}

export default SubscriptionService;
