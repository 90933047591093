import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Equipment from '../Equipment/Equipment';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import AndroidIcon from '@mui/icons-material/Android';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import DownloadWin from './DownloadOptions/DownloadWin';
import DownloadMac from './DownloadOptions/DownloadMac';
import DownloadAndroid from './DownloadOptions/DownloadAndroid';
import DownloadIOS from './DownloadOptions/DownloadIOS';
import classes from './DownloadApp.module.css';

function DownloadApp() {
    const intl = useIntl();
    const [value, setValue] = useState('1');

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <section id="download" className={classes['download']} aria-labelledby="download_title">
            <CustomContainer>
                <Typography variant="h2" component="h2" color="primary" id="download_title">
                    <FormattedMessage
                        defaultMessage="Скачать WattAttack"
                        id="downloadAppTitle"
                        description="скачать"
                    />
                </Typography>

                <Box className={classes['download__tabs-wrapper']}>
                    <TabContext value={value}>
                        <TabList
                            onChange={handleChange}
                            aria-label={intl.formatMessage({
                                defaultMessage: 'Выбор операционной системы',
                                id: 'downloadAppSelectOS',
                                description: 'скачать'
                            })}
                            className={classes['download__options']}
                        >
                            <Tab
                                label="Windows"
                                value="1"
                                icon={<DesktopWindowsIcon />}
                                iconPosition="end"
                                className={classes['download__option-name']}
                            />
                            <Tab
                                label="MacOS"
                                value="2"
                                icon={<LaptopMacIcon />}
                                iconPosition="end"
                                className={classes['download__option-name']}
                            />
                            <Tab
                                label="Android"
                                value="3"
                                icon={<AndroidIcon />}
                                iconPosition="end"
                                className={classes['download__option-name']}
                            />
                            <Tab
                                label="iOS"
                                value="4"
                                icon={<SmartphoneIcon />}
                                iconPosition="end"
                                className={classes['download__option-name']}
                            />
                        </TabList>
                        <TabPanel value="1">
                            <Typography variant="h3" component="h3">
                                <FormattedMessage
                                    defaultMessage="Системные требования"
                                    id="downloadAppRequirements"
                                    description="скачать"
                                />
                            </Typography>
                            <DownloadWin />
                        </TabPanel>
                        <TabPanel value="2">
                            <DownloadMac />
                        </TabPanel>
                        <TabPanel value="3">
                            <DownloadAndroid />
                        </TabPanel>
                        <TabPanel value="4">
                            <DownloadIOS />
                        </TabPanel>
                    </TabContext>
                </Box>
                <Equipment />
            </CustomContainer>
        </section>
    );
}

export default DownloadApp;
